import { SEO } from 'components/blocks/seo';
import Mdx from 'components/containers/mdx';
import styles from 'components/pages/jobs/jobs.module.scss';
import { CTA } from 'components/shared/cta';
import { Heading } from 'components/shared/heading';
import { PageInfo } from 'components/shared/page-info';
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React, { useEffect, useState } from 'react';
import SEO_DATA from 'utils/seo-metadata';

const Jobs = ({ data: { file } }) => {
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    fetch(`${process.env.GATSBY_DEFAULT_MAIN_URL}/data/jobs-positions.json`)
      .then((response) => response.json())
      .then((data) => setJobs(data))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch jobs: ', error);
      });
  }, []);

  return (
    <DefaultLayout>
      <PageInfo
        title={'Jobs'}
        className={styles.pageInfo}
        description={
          /* eslint-disable-next-line max-len */
          'Appreciate speed? Consider yourself an honest person with a great deal of integrity, humility and passion? If so, join us!'
        }
        withBgPattern
      />

      <div className={`container ${styles.wrapper}`}>
        <div className={'row'}>
          <div className={'col-lg-6 col-12'}>
            <Heading className={styles.jobHeading} tag={'h2'}>
              {jobs && jobs.length > 0 ? 'Current Openings' : 'No open positions at this time'}
            </Heading>
            <ul className={styles.jobList}>
              {jobs &&
                jobs.length > 0 &&
                jobs.map(({ title, location, link }, i) => (
                  <li className={styles.jobItemWrapper} key={`jp-${i}`}>
                    <a href={link} className={styles.jobItem}>
                      <p className={styles.jobTitle}>{title}</p>
                      <p className={styles.jobLocation}>{location}</p>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <div className={'col-lg-6 col-12'}>
            <Mdx content={file.children[0].body} mode="plain" />
          </div>
        </div>
      </div>
      <CTA
        title={"Don't see an opening that suits you?"}
        description={
          'Check out all the open positions at Grafana. We are building the next generation of open-source tools.'
        }
        buttonText={'Careers at Grafana Labs'}
        buttonRef={'https://grafana.com/about/careers/open-positions'}
        themeLight
      />
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    file(name: { eq: "jobs" }, relativeDirectory: { eq: "markdown" }) {
      children {
        ... on Mdx {
          body
        }
      }
    }
  }
`;

export default Jobs;

export const Head = () => <SEO {...SEO_DATA.jobs} />;
